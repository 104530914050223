<template>
  <div class="news">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">供应链金融奖励申请</div>
    <div class="jl_box">
      <img :src="require(`../../assets/img/jl${rId}.png`)" alt="">
      <div class="jl_text">
        <p v-if="rId==='1'">应收账款融资<br>模式奖励</p>
        <p v-else-if="rId==='2'">跨境金融区块链<br>服务平台接入机构奖励</p>
        <p v-else-if="rId==='3'">供应链金融<br>第三服务机构奖励</p>
        <p v-else-if="rId==='4'">供应链金融票据<br>签发奖励</p>
        <p v-else-if="rId==='5'">供应链信息<br>平台奖励</p>
        <p v-else>金融机构服务<br>供应链金融奖励</p>
      </div>
    </div>
    <div class="news-tab">
      <div class="tab" @click="changeTab(1)">
        <p :class="{ 'active': isActive=='1'}">申请概要<br>详情</p>
      </div>
      <div class="tab" @click="changeTab(2)">        
        <p :class="{ 'active': isActive=='2'}">奖励范围<br>和标准</p>
      </div>
      <div class="tab" @click="changeTab(3)">
        <p :class="{ 'active': isActive=='3'}">申报条件<br>及材料</p>        
      </div>
      <div class="tab" @click="changeTab(4)">
        <p :class="{ 'active': isActive=='4'}" style="line-height: 10.5vw">申报方法</p>        
      </div>
    </div>
    <div class="tab-content" v-show="isActive=='1'">
      <p>总奖励资金300万元；已有0家企业申请；0家企业申请通过。</p>
    </div>
    <div class="tab-content" v-show="isActive=='2'">
      <p style="text-indent: 2em;">自治区财政每年安排300万元奖励资金，按照“先到先得，用完即止”的原则，对广西区内业务量达到一定规模且直接接入跨境金融区块链服务平台的信息平台给予50万元的一次性奖励；对在广西区内设立并与3家以上金融机构实现信息互联互通的第三方专业机构，按照其支持金融机枃开展供应链融资额的0.2%给予奖励，单家机构年度奖励金额最高为50万元。</p>
    </div>
    <div class="tab-content" v-show="isActive=='3'">
      <p class="b">申报条件：</p>
      <p class="rowa">申报企业须为</p>
      <p class="rowa">1.在广西自治区内注册的法人机构。</p>
      <p class="rowa">2.申报企业所属或者服务的行业、须符合国家和我区产业政策。</p>
      <p class="rowa">3.申报企业无恶意套取财政奖补资金行为。</p>
      <p class="rowa">4.核心企业名单以各行业主管部门认定为准。</p>
      <p class="b">申报材料：</p>
      <p class="rowa">1.《____年度广西跨境金融区块链服务平台接入机构奖励申请表》（桂信融官网下载）。</p>
      <p class="rowa">2.申报企业资质材料：包括营业执照复印件等。</p>
      <p class="rowa">3.应用场景说明资料：第三方物流数据接入的跨境金融区块链服务平台场景说明，包括物流数据来源、数据要素内容、核验流程、应用成效等。</p>
      <p class="rowa">4.对接技术说明材料：包括接入跨境金融区块链服务平台的技术模式说明以及年度内完善平台、补充数据、优化接口等其他相关举措的说明材料等。</p>
      <p class="rowa">5.业务量说明材料：包括年度内金融机构、企业通过跨境金融区块链服务平台核验的第三方物流数据记录数，及由此服务办理完成的业务总金额、总笔数、服务企业家数等材料</p>
      <p class="rowa">6.主管部门要求提供的其他材料。</p>
      <p class="rowa" style="padding-bottom: 5vw">以上材料须加盖公章。</p>
    </div>
    <div class="tab-content" v-show="isActive=='4'">
      <div class="center">
        <p style="text-indent: 2em;">打开个人电脑,打开浏览器进入桂信融官网地址 : <span>https://gxr. dsjfzj.gxzf.gov.cn/</span>，然后填写资料和上传附件办理即可。</p>
        
      </div>
   
    </div>

    
  </div>
</template>
<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
   setup() {
     let router = useRouter();
     let rId = router.currentRoute.value.query.rId;
    //  console.log(rId,888);
     const isActive = ref(1);
     const changeTab = (val) =>{
       isActive.value = val;
     }
    return {
      rId,
      isActive,
      changeTab
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.news {
  padding-top: 12.5vw;
  height: calc(100vh - 12.5vw);
  display: flex;
  flex-direction: column;
  .jl_box {
    display: flex;
    background-color: #fff;
    align-items: center;
    margin: 2vw 2vw 0;
    padding: 1vw 4vw;
    border-radius: 2vw;
    box-shadow: 0 0 3vw rgba(0,0,0,0.1);
    img {
      width: 19vw;
    }
    .jl_text {
      margin-left: 2vw;
      padding-left: 4vw;
      border-left: 1px solid #ccc;
      p {
        font-size: 4vw;
        font-weight: 500;
        line-height: 7vw;
      }
      
    }
  }
  .news-tab {
    display: flex;
    justify-content: space-around;
    margin: 1vw 2vw;
    .tab {
      background-color: #fff;
      border-radius: 2vw;
      width: 24%;
      font-size: 4vw;
      color: #999;
      padding-top: 1vw;
      display: flex;
      align-items:center;
      justify-content: center;
      
      p {
        font-size: 4vw;
        color: #999;
        padding: 1vw 0;
        text-align: center;
        border-bottom: 1vw solid #fff;
      }
      .active {
        color: #fc9a26;
        border-bottom: 1vw solid #fc9a26;
      }
    }
    
    
  }
  .tab-content {
    flex: 1;
    background-color: #fff;
    font-size: 4vw;
    padding: 4vw;
    margin: 0 2vw 2vw;
    color: #333;
    border-radius: 2vw;
    min-height: calc(100vh - 80vw);
    // text-align: center;
    .b {
      font-weight: 600;
      font-size: 4.5vw;
      line-height: 8vw;
    }
    .rowa {
      text-indent: 2em;
    }
    .center {
      // margin-top: 25vh;
      display: flex;
      align-items:center;
      justify-content: center;
      span {
        color: #fc9a26
      }
    }
  }
}
</style>
